<template lang="pug">
  .components-view
    .st-container
      .components-view__header
        h1 Components demo page
        button.btn-main(@click="goBack")  To skilltree
      .components-view__nav
        v-tabs.mb-4(:options="navOptions")
      v-scrollable
        .components-view__inner
          router-view
</template>

<script>
import NavTabs from '@/components/ui/NavTabs'

export default {
  name: 'Components',

  components: {
    NavTabs
  },

  data: () => ({
    navOptions: [
      { id: 0, title: 'VSelect', path: '/components/v-select' },
      { id: 1, title: 'VImageSelect', path: '/components/v-image-select' },
      { id: 2, title: 'VColorSelect', path: '/components/v-color-select' },
      { id: 3, title: 'VTabs', path: '/components/v-tabs' },
      { id: 4, title: 'VBadge', path: '/components/v-badge' },
      { id: 5, title: 'VBtn', path: '/components/v-btn' },
      { id: 6, title: 'VChip', path: '/components/v-chip' }
    ]
  }),

  methods: {
    goBack () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss">
  .components-view {
    padding-top: 80px;

    &__inner {
      padding-right: 30px;
    }

    .components-view__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;

      .btn-main {
        position: absolute;
        left: 0;
        max-width: 100px;
      }
    }

    .component-card {
      padding: 20px;
      border-radius: 5px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
    }

    .element-item {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;

      code {
        margin-bottom: 10px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .toggler-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
</style>
