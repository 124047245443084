<template lang="pug">
  div
    ul.kt-pills.nav.nav-pills
      li.nav-item(v-for="(item, index) in options" :key="item.id"  )
        router-link.nav-link(:class="{ active: isActive(item) }"
          :to="item.path || ''") {{ item.title | translate  }}
</template>

<script>
export default {
  name: 'UiNavTabs',

  props: {
    options: Array
  },

  methods: {
    isActive (item) {
      return this.$route.path.includes(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-pills {
    margin-bottom: 0!important;
  }

  .nav-link {
    cursor: pointer;
  }
</style>
